import React from 'react'


declare global {
  interface Window {
    __DEVICE__: DeviceContext & {
      updateCssVariables?: () => void
    }
  }

  type DeviceContext = {
    os: string
    platform: string
    browser: string
    browserVersion: string
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
    isBot: boolean
    isSearchBot: boolean
  }

  type DeviceProvider = React.FC<{
    children?: React.ReactNode
    value: DeviceContext
  }>
}

const Context = React.createContext<DeviceContext>(null)


export default Context
